import { Link } from "gatsby"
import React from "react"

const NoScript = () => {
  return (
    <>
      <div className="container text-center">
        <h1>Sorry!</h1>
        <p>This website requires JavaScript to be enabled.</p>
        <Link className="button button--primary" to="/">
          Return To Home
        </Link>
      </div>
    </>
  )
}

export default NoScript
